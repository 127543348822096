const backendUri = 'https://manager-api-test.tuacar.it';
const production = true;
const version = '2.0.1';

export const environment = {
  production: production,
  version: version,
  backendBaseUri: backendUri,
  graphqlUri: backendUri + '/graphql',
  uploadPictureUri: backendUri + '/car/picture',
  crmUrl: 'https://crm-test.tuacar.it',
  webSiteUrl: 'https://tua-car.it',
  gestionaleAutoUrl: 'https://console.gestionaleauto.com/login',
  damBaseUrl: 'https://ik.imagekit.io/tuacar/stg',
  mobilePictureUploadUrl: 'https://manager-mobile-test.tuacar.it/',
  wasabiUrl: 's3.eu-central-2.wasabisys.com',
  wasabiBucket: 'tuacar-stg',
  wasabiAccessKey: '7FYEEI7EUL3RS0UP4N6B',
  wasabiSecretKey: '1cXuN5He5c93AvFbe4zsd7Cj2pAPz37DC17TZNBa',
  auth0Domain: 'tuacar-stg.eu.auth0.com',
  auth0ClientId: 'lo96Dql7rzkm7GyQdUJNv9tqbYQBeqq2',
  env: 'staging',
  sentryDsn:
    'https://c4545a58bece6e267ad4b4a8b88da6e8@o4505641054502912.ingest.sentry.io/4505669307006976',
  ablyKey: '1fI92Q.mX8hoQ:wVX4q5hQoyk1wMKisDIzNvWaZ5u8Lhl6byuD40g4INw',
};
