import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  AuthHttpInterceptor,
  AuthModule,
} from '@auth0/auth0-angular';
import { UiModule } from '@tuacar-workspace/ui';
import { CoreModule, reducers, SharedEffects } from '@tuacar-workspace/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { provideStore, StoreModule } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';

import {
  provideRouterStore,
  RouterState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import * as fromRoot from './+state/root.reducer';
import { RootEffects } from './+state/root.effects';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MenuComponent as ErpMenuComponent } from '@tuacar-workspace/erp/menu';
import { MenuComponent as AnagraficheMenuComponent } from '@tuacar-workspace/anagrafiche/menu';
import { GraphQLModule } from './graphql.module';
import { registerLocaleData } from '@angular/common';
import { NZ_I18N, it_IT, NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import localeIt from '@angular/common/locales/it';
import { environment } from '@tuacar-workspace/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TitleComponent } from './components/title.component';
import * as Sentry from '@sentry/angular';
import { HomeComponent } from './components/home.component';
import { it } from 'date-fns/locale';
import { StatisticheMenuComponent } from '@tuacar-workspace/statistiche/menu';
import { SettingsMenuComponent } from '@tuacar-workspace/settings/menu';
import { FormsModule } from '@angular/forms';
import { TuarentMenuComponent } from 'tuarent-menu';

registerLocaleData(localeIt, 'it');

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'erp', pathMatch: 'full' },
      { path: '', redirectTo: 'erp', pathMatch: 'full', outlet: 'menu' },
      {
        path: 'erp',
        component: ErpMenuComponent,
        outlet: 'menu',
      },
      {
        path: 'erp',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@tuacar-workspace/erp/dashboard').then(
            (m) => m.ErpDashboardModule,
          ),
      },
      {
        path: 'anagrafiche',
        component: AnagraficheMenuComponent,
        outlet: 'menu',
      },
      {
        path: 'anagrafiche',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@tuacar-workspace/anagrafiche/management').then(
            (m) => m.AnagraficheManagementModule,
          ),
      },
      {
        path: 'statistiche',
        component: StatisticheMenuComponent,
        outlet: 'menu',
      },
      {
        path: 'statistiche',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'venduto',
            pathMatch: 'full',
          },
          {
            path: 'venduto',
            loadChildren: () =>
              import('@tuacar-workspace/statistiche/venduto').then(
                (m) => m.statisticheVendutoRoutes,
              ),
          },
          {
            path: 'garanzie',
            loadChildren: () =>
              import('@tuacar-workspace/statistiche/garanzie').then(
                (m) => m.garanzieRoutes,
              ),
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsMenuComponent,
        outlet: 'menu',
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@tuacar-workspace/settings/dashboard').then(
            (m) => m.dashboardRoutes,
          ),
      },
      {
        path: 'tuarent',
        component: TuarentMenuComponent,
        outlet: 'menu',
      },
      {
        path: 'tuarent',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('tuarent-landing').then((m) => m.tuarentLandingRoutes),
      },
    ],
  },
];

@NgModule({
  declarations: [AppComponent, TitleComponent, HomeComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    UiModule,
    CoreModule,
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
    GraphQLModule,
    BrowserAnimationsModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      issuer: environment.auth0Domain,
      // useRefreshTokens: true,
      authorizationParams: {
        audience: 'https://manager.tuacar.it',
        redirect_uri: window.location.origin,
      },
      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          // {
          //   // Match any request that starts 'https://YOUR_DOMAIN/api/v2/' (note the asterisk)
          //   uri: 'api/graphql',
          //   // tokenOptions: {
          //   //   // The attached token should target this audience
          //   //   audience: 'https://YOUR_DOMAIN/api/v2/',
          //   //   // The attached token should have these scopes
          //   //   scope: 'read:current_user'
          //   // }
          // },
          // {
          //   uri: environment.graphqlUri,
          // },
          {
            uri: environment.backendBaseUri + '*',
          },
        ],
      },
    }),
    StoreModule.forRoot(fromRoot.reducers, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    StoreModule.forFeature(fromRoot.ROOT_FEATURE_KEY, fromRoot.rootReducer),
    EffectsModule.forFeature([RootEffects]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: it_IT },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: NZ_DATE_LOCALE, useValue: it },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideStore(reducers),
    provideEffects([SharedEffects]),
    provideStoreDevtools({ connectInZone: true }),
    provideRouterStore(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
