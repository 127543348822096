import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { AuthService, User } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { GET_PROFILE } from './queries.graphql';
import { ProfileModel } from '../models/core.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  getUser(): Observable<User | null | undefined> {
    return this.auth.user$;
  }

  getProfile(): Observable<ProfileModel> {
    return this.apollo
      .watchQuery({
        query: GET_PROFILE,
        returnPartialData: false,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const car = result.data.me as ProfileModel;
          return car;
        }),
      );
  }

  logout() {
    Sentry.setUser(null);
    localStorage.removeItem('user');

    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  constructor(
    public auth: AuthService,
    private apollo: Apollo,
  ) {}
}
